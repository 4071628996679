<template>
  <div class="p-4">
    <CustomDatatable
      id="table-validation-cotation"
      :isPaginated="true"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
      @refreshData="refreshData($event)"
      @rechercheData="rechercheData($event)"
      :paginationObjectProps="paginationObject"
    >
      <template v-slot:body>
        <tbody class="text-center" v-if="listeComptes.length > 0">
          <tr v-for="element in listeComptes" :key="element.id">
            <td>{{ element.nom }}</td>
            <td>{{ element.prenom }}</td>
            <td>
              <div v-if="element.email.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      element.email.substring(0, 20) + "..."
                    }}</span>
                  </template>
                  <span>{{ element.email }}</span>
                </v-tooltip>
              </div>
              <span v-else>{{ element.email }}</span>
            </td>
            <td>{{ element.telephone }}</td>
            <td>{{ element.date }}</td>
            <td>{{ element.type }}</td>
            <td>{{ element.societeGroupe }}</td>
            <td>{{ element.equipename }}</td>
            <td>{{ element?.supprimer == 0 ? "Activé" : "Désactivé" }}</td>
            <td>
              <div class="d-flex flex-row justify-center align-center">
                <v-btn icon @click="openDialogChangePasswordUser(element)">
                  <v-icon size="25" :color="$colors[0]">mdi-shield-lock-outline</v-icon>
                </v-btn>
                <v-btn icon @click="openDialogChangeStatutUser(element)">
                  <v-icon size="25" :color="$colors[0]">mdi-account-edit-outline</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </CustomDatatable>

    <div v-if="dialogChangeCompte">
      <v-dialog width="330" v-model="dialogChangeCompte">
        <v-card
          class="d-flex flex-column justify-space-between"
          height="500"
          width="100%"
        >
          <v-card-title class="ma-0 pa-0">
            <v-toolbar height="50" :color="$colors[0]" class="text-white" dark>
              <!--<span>mot de passe {{ compte.username }}</span>-->
            </v-toolbar>
          </v-card-title>
          <div class="d-flex flex-column align-center justify-center my-4">
            <v-icon :color="$colors[0]" size="80">mdi-shield-lock-outline</v-icon>
            <span class="Poppins-Bold mt-4">changement mot de passe</span>
          </div>
          <v-card-text class="pa-4">
            <v-form ref="formPassword">
              <v-text-field
                outlined
                class="my-2"
                dense
                :rules="[$rules.required]"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                label="nouveau mot de passe"
                v-model="nouveauMDP"
              ></v-text-field>
              <v-text-field
                outlined
                class="my-2"
                dense
                :rules="[
                  $rules.required,
                  $rules.matchingPassword(nouveauMDP, confirmationMDP),
                ]"
                :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmNewPassword ? 'text' : 'password'"
                @click:append="showConfirmNewPassword = !showConfirmNewPassword"
                label="confirmation mot de passe"
                v-model="confirmationMDP"
              >
              </v-text-field>
            </v-form>
            <v-btn
              @click="generatePassword()"
              class="white--text tex-center mt-4"
              width="100%"
              elevation="0"
              :color="$colors[3]"
              >Générer mot de passe</v-btn
            >
            <v-btn
              class="white--text tex-center mt-4"
              width="100%"
              :loading="loadingChangePassword"
              @click="changementPassword(compte)"
              elevation="0"
              :color="$colors[0]"
              >Confirmer</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialogChangeStatut">
      <v-dialog width="750" v-model="dialogChangeStatut">
        <v-card
          class="d-flex flex-column justify-space-between"
          height="400"
          width="100%"
        >
          <v-card-title class="ma-0 pa-0">
            <v-toolbar height="50" :color="$colors[0]" class="text-white" dark>
              <!--<span>mot de passe {{ compte.username }}</span>-->
            </v-toolbar>
          </v-card-title>
          <div class="d-flex flex-column align-center justify-center my-4">
            <v-icon :color="compte.supprimer == 0 ? 'red' : 'green'" size="80">{{
              compte.supprimer == 0
                ? "mdi-account-cancel-outline"
                : "mdi-account-reactivate-outline"
            }}</v-icon>
            <span class="Poppins-Bold mt-4"
              >Vous êtes sur le point
              {{ compte.supprimer == 0 ? "de désactiver" : "d'activer" }} le compte :
              {{ compte.email }}</span
            >
          </div>
          <v-card-actions>
            <v-col>
              <v-btn
                @click="dialogChangeStatut = false"
                class="white--text tex-center mt-4"
                width="100%"
                elevation="0"
                :color="$colors[3]"
                >Annuler</v-btn
              >
              <v-btn
                class="white--text tex-center mt-4"
                width="100%"
                :loading="loadingChangeStatut"
                @click="changementStatut(compte)"
                elevation="0"
                :color="$colors[0]"
                >Confirmer</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <Snackbar
      :model="snackbarAPI.model"
      :message="snackbarAPI.message"
      :color="snackbarAPI.color"
    />
    <Loading
      class="pa-0"
      :dialogProps="loading.dialogLoading"
      :messageDialogeProps="loading.messageError"
      :loadingProps="loading.loadingValue"
      :errorProps="loading.errorValue"
      @closeDialog="closeDialog($event)"
    />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import Pagination from "@/components/datatable/Pagination.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "ComptesView",
  components: {
    CustomDatatable,
    Pagination,
    Snackbar,
    Loading,
  },
  data() {
    return {
      loading: {
        errorValue: false,
        loadingValue: false,
        messageError: "",
        dialogLoading: false,
      },
      snackbarAPI: {
        message: "",
        color: "",
        model: false,
      },
      showConfirmNewPassword: false,
      showNewPassword: false,
      nouveauMDP: "",
      confirmationMDP: "",
      compte: {},
      dialogChangeCompte: false,
      dialogChangeStatut: false,
      loadingChangeStatut: false,
      loadingChangePassword: false,
      listeComptes: [],
      paginationObject: {},
      sortKey: "",
      sortOrders: "",
      sortBy: "",
      paginationVisible: true,

      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      columns: [
        { width: "10vw", label: "Nom", name: "nom", sortable: true },
        { width: "10vw", label: "Prénom", name: "prenom", sortable: true },
        { width: "10vw", label: "Email", name: "email", sortable: true },
        { width: "10vw", label: "Téléphone", name: "telephone", sortable: false },
        { width: "10vw", label: "Embauche", name: "date", sortable: true },
        { width: "10vw", label: "Poste", name: "type", sortable: false },
        { width: "10vw", label: "Société Groupe", name: "societeGroupe", sortable: true },
        { width: "10vw", label: "Equipe", name: "hasEquipe", sortable: false },
        { width: "10vw", label: "Statut", name: "statut", sortable: false },
        { width: "10vw", label: "Actions", name: "actions", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getAllComptes();
  },
  methods: {
    annulerChangement() {
      this.nouveauMDP = "";
      this.confirmationMDP = "";
      this.dialogChangeCompte = false;
    },
    generatePassword() {
      var length = 25,
        charset =
          "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.nouveauMDP = retVal;
      this.confirmationMDP = this.nouveauMDP;
    },
    async changementStatut(compte) {
      this.snackbarAPI.model = false;
      this.loadingChangeStatut = true;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeUserStatusForAdminDashboard/",
        method: "POST",
        data: {
          token: this.$getToken("token"),
          id: compte.id,
          supprimer: compte.supprimer,
        },
      }).then((res) => {
        if (res.data.result) {
          this.snackbarAPI.color = "success";
          compte.supprimer = res.data.status;
        } else {
          this.snackbarAPI.color = "error";
        }
        this.snackbarAPI.model = true;
        this.snackbarAPI.message = res.data.message;
        this.loadingChangeStatut = false;
        this.dialogChangeStatut = false;
      });
    },
    async changementPassword(compte) {
      this.snackbarAPI.model = false;
      this.loading.dialogLoading = true;
      this.loading.loadingValue = true;
      if (this.$refs.formPassword.validate()) {
        this.loadingChangePassword = true;
        await this.$axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "changeUserPassword/",
          method: "POST",
          data: {
            token: this.$getToken("token"),
            id: compte.id,
            password: this.confirmationMDP,
          },
        }).then((res) => {
          if (res.data.result) {
            this.snackbarAPI.color = "success";
          } else {
            this.snackbarAPI.color = "error";
          }
          this.loading.loadingValue = false;
          this.loading.dialogLoading = false;
          this.snackbarAPI.model = true;
          this.snackbarAPI.message = res.data.message;
          this.nouveauMDP = "";
          this.confirmationMDP = "";
        });
        this.snackbarMessage = true;
        this.dialogChangeCompte = false;
        this.loadingChangePassword = false;
      }
    },
    openDialogChangePasswordUser(objectCompte) {
      this.dialogChangeCompte = true;
      this.compte = objectCompte;
    },
    openDialogChangeStatutUser(objectCompte) {
      this.dialogChangeStatut = true;
      this.compte = objectCompte;
    },
    async getAllComptes() {
      this.loading.dialogLoading = true;
      this.loading.loadingValue = true;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listeCompteDataTable/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: 1,
          token: this.$getToken("token"),
        },
      }).then((res) => {
        this.listeComptes = res.data.data.data.data;
        this.paginationObject = res.data.data.data;
        this.loading.loadingValue = false;
        this.loading.dialogLoading = false;
      });
    },
    async rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.loading.dialogLoading = true;
        this.loading.loadingValue = true;
        this.tableData.length = key;
      }
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listeCompteDataTable/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: this.paginationObject.current_page,
          token: this.$getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.listeComptes = data.data.data;
              this.paginationObject = data.data;
              this.loading.loadingValue = false;
              this.loading.dialogLoading = false;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async refreshData(pageNumber = 1) {
      this.loading.dialogLoading = true;
      this.loading.loadingValue = true;
      this.tableData.draw++;
      await this.$axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "listeCompteDataTable/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.listeComptes = data.data.data;
              this.paginationObject = data.data;

              this.loading.loadingValue = false;
              this.loading.dialogLoading = false;
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<style scoped>
::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #01579b !important;
}

::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset:focus {
  border-color: #01579b !important;
}
</style>
